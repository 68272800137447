import React from 'react';

import Layout from '../components/templates/layout';
import SEO from '../components/templates/seo';

const ImprintPage = () => (
  <Layout pageInfo={{ pageName: 'imprint' }}>
    <SEO title="MissionMe - Impressum" />
    <h1>Impressum</h1>
    <p>
      <strong>MissionMe / Gruner + Jahr Deutschland GmbH</strong>
    </p>
    <p>
      Koreastraße 7 <br />
      20457 Hamburg <br />
      info@missionme.de <br />
    </p>

    <p>
      Eingetragen beim Amtsgericht Hamburg, HRB 145255
      <br />
      USt-ID: DE 310194027
    </p>
    <p>
      <strong>Verantwortliche im Sinne von § 55 Abs. 2 RStV: </strong> Anna Wicher
    </p>
    <p>
      <strong>Geschäftsführung: </strong>Bernd Hellermann und Ingrid Heisserer
    </p>

    <h4>Urheberrecht und andere Schutzrechte</h4>
    <p>
      Die Inhalte der Website www.missionme.de sind urheberrechtlich geschützt. Reproduktion von Bildern und/oder Texten (auch auszugsweise)
      sind nur mit vorheriger schriftlicher Genehmigung möglich.
    </p>
    <p>
      Die Nutzung sämtlicher Inhalte dieses Angebots (insbesondere Texte, Bilder, Grafiken, Videos, Audioinhalte und sonstiger begleitender
      Daten) für kommerzielles Text und Data Mining im Sinne des § 44b UrhG ist nicht statthaft.
    </p>

    <h4>Gewährleistung und Haftung</h4>
    <p>
      Die Gruner + Jahr Deutschland GmbH bemüht sich im Rahmen des Zumutbaren, auf dieser Website richtige und vollständige Informationen
      zur Verfügung zu stellen. Eine Gewährleistung oder Haftung für die Informationen, insbesondere für deren Vollständigkeit, Richtigkeit
      und Aktualität, kann jedoch nicht übernommen werden. Jegliche Haftung für Schäden, die direkt oder indirekt aus der Benutzung dieser
      Website entstehen, wird ausgeschlossen, soweit diese nicht auf Vorsatz oder grober Fahrlässigkeit der Gruner + Jahr Deutschland GmbH
      oder ihrer Erfüllungsgehilfen beruhen. Die Website der Gruner + Jahr Deutschland GmbH enthält Links zu anderen Websites. Die Gruner +
      Jahr Deutschland GmbH ist für den Inhalt dieser anderen Websites nicht verantwortlich und distanziert sich ausdrücklich davon. Die
      Gruner + Jahr Deutschland GmbH behält sich das Recht vor, ohne vorherige Ankündigung Änderungen oder Ergänzungen der bereitgestellten
      Informationen vorzunehmen.
    </p>
    <p>
      Weitere Informationen finden Sie unter <a href="http://www.guj.de/">Gruner+Jahr</a>.
    </p>
  </Layout>
);

export default ImprintPage;
